import { useNavigate } from "@solidjs/router";
import { type JSX, createEffect } from "solid-js";
import { userStore } from "@/domain/category/user/user.store";

export function IsAuthenticatedRouteGuard({
  children,
}: {
  children?: JSX.Element;
}) {
  const navigate = useNavigate();

  createEffect(() => {
    if (!userStore.user) {
      navigate("/login", { replace: true });
    }
  });

  return children;
}
