import { For, createEffect, createSignal, onMount } from "solid-js";
import { Player, type PlayerApiType } from "../../components/Player";
import { WordCard, type WordType } from "../../components/WordCard";
import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
} from "../../components/ui/carousel";
import { cn } from "../../helpers/cn";

interface WordPageParamsType {
  words: WordType[];
}
export function WordsPage({ words }: WordPageParamsType) {
  const [carouselApi, setCarouselApi] = createSignal<CarouselApi>();
  let playerApi: PlayerApiType;
  const [current, setCurrent] = createSignal(0);

  function playSound(soundSrc: string) {
    playerApi?.play(soundSrc);
  }

  createEffect(() => {
    const apiInstance = carouselApi();
    if (!apiInstance) {
      return;
    }
    setCurrent(apiInstance.selectedScrollSnap() + 1);
    apiInstance.on("select", () => {
      setCurrent(apiInstance.selectedScrollSnap() + 1);
    });
  });

  createEffect(() => {
    const word = words?.[current() - 1];
    if (word) {
      playerApi?.play(word.soundUrl);
    }
  });

  onMount(() => {
    const word = words?.[0];
    if (word) {
      playerApi?.play(word.soundUrl);
    }
  });

  return (
    <div class="flex flex-col items-center pt-4 space-y-4">
      <Carousel setApi={setCarouselApi} class="w-full">
        <CarouselContent class="-ml-4 cursor-grabbing">
          <For each={words}>
            {(word, index) => (
              <CarouselItem
                class={cn(
                  "basis-10/12",
                  index() === 0 ? "pl-8" : "pl-4",
                  current() === words.length ? "pr-4" : undefined
                )}
              >
                <div
                  class={cn(
                    "transition-transform duration-300",
                    current() !== index() + 1 ? "scale-90 opacity-90" : ""
                  )}
                >
                  <WordCard word={word} playSound={playSound} />
                </div>
              </CarouselItem>
            )}
          </For>
        </CarouselContent>
      </Carousel>
      <Player
        ref={(api) => {
          playerApi = api;
        }}
      />
    </div>
  );
}
