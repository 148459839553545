import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ImageRoot, Image, ImageFallback } from "@/components/ui/image";
import type { CategoryType } from "@/domain/category/category.hook";
import { type JSX, Show } from "solid-js";
import { cn } from "@/helpers/cn";

interface CategoryCardParamsType {
  category: CategoryType;
  href: string;
  size?: "default" | "small";
  highPriority?: boolean;
  extraDescription?: JSX.Element;
  actions?: JSX.Element;
}
export function CategoryCard({
  category,
  href,
  size,
  highPriority,
  extraDescription,
  actions,
}: CategoryCardParamsType) {
  const imageVariants = {
    containerClass: size === "small" ? "h-24 w-24" : "h-32 w-32",
    imgSize: size === "small" ? 96 : 192,
  };
  return (
    <Card href={href}>
      <CardHeader>
        <CardTitle>{category.title}</CardTitle>
        <CardDescription>
          <span class="line-clamp-1">{category.description}</span>
          <Show when={!!extraDescription}>{extraDescription}</Show>
        </CardDescription>
      </CardHeader>

      <CardContent>
        <div class="flex justify-center">
          <ImageRoot
            fallbackDelay={600}
            class={cn("shadow-xl", imageVariants.containerClass)}
          >
            <Image
              src={category.imageUrl}
              width={imageVariants.imgSize}
              height={imageVariants.imgSize}
              alt={`${category.title} picto`}
              fetchpriority={highPriority ? "high" : "low"}
              loading={highPriority ? "eager" : "lazy"}
            />
            <ImageFallback>⬜</ImageFallback>
          </ImageRoot>
        </div>
      </CardContent>

      <Show when={!!actions}>
        <CardFooter>{actions}</CardFooter>
      </Show>
    </Card>
  );
}
