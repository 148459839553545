import { A } from "@solidjs/router";
import { For, type JSX } from "solid-js";

export type LeftMenuItemType = {
  label: string;
  href: string;
  icon?: JSX.Element;
};
export interface LeftMenuProps {
  items: LeftMenuItemType[];
  categories: LeftMenuItemType[];
  onClick: () => void;
}
export function LeftMenu({ categories, items, onClick }: LeftMenuProps) {
  return (
    <nav class="p-4">
      <ul>
        <For each={items}>
          {(item) => <MenuItem item={item} onClick={onClick} />}
        </For>
      </ul>
      <h2 class="text-gray-700 text-xl font-bold mt-10">Categories</h2>
      <ul class="pl-4">
        <For each={categories}>
          {(item) => <MenuItem item={item} onClick={onClick} />}
        </For>
      </ul>
    </nav>
  );
}

function MenuItem({
  item,
  onClick,
}: {
  item: LeftMenuItemType;
  onClick: () => void;
}) {
  return (
    <li>
      <A
        href={item.href}
        class="flex p-2 gap-x-4 hover:bg-gray-200 rounded-md"
        inactiveClass="text-gray-700"
        activeClass="font-bold text-gray-900"
        end
        onClick={onClick}
      >
        <span class="opacity-80">{item.icon} </span>
        <span> {item.label}</span>
      </A>
    </li>
  );
}
