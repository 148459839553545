import { type ButtonVariantType, buttonVariants } from "@/components/ui/button";
import { A } from "@solidjs/router";
import type { JSX } from "solid-js";

export function AdminLinkButton({
  href,
  icon,
  variant,
  children,
}: {
  variant: ButtonVariantType;
  href: string;
  icon: JSX.Element;
  children: JSX.Element;
}) {
  return (
    <A href={href} class={buttonVariants({ variant })}>
      <span class="flex gap-x-2 items-center">
        {icon} <span>{children}</span>
      </span>
    </A>
  );
}
