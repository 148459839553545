import { masterDarijaClient } from "@/infrastructure/query.client";
import type { UploadAssetType } from "@master-darija/contract";
import { createMutation, useQueryClient } from "@tanstack/solid-query";
import { toast } from "solid-sonner";
import { searchAssetsQuery } from "../asset/asset.hook";

export function useCreateAsset() {
  const queryClient = useQueryClient();
  const createAsset = createMutation(() => ({
    mutationFn: async (body: UploadAssetType) => {
      const result = await masterDarijaClient.adminAssets.uploadAsset({
        body,
      });
      if (result.status < 200 || result.status >= 400) {
        toast.error("Failed to upload asset");

        throw new Error("Failed to fetch data");
      }
      return result.body;
    },

    onSuccess: () => {
      queryClient.invalidateQueries(
        searchAssetsQuery({ assetFamily: "categoryIcon" })
      );
    },
  }));

  return createAsset;
}
