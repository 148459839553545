import { For, Show } from "solid-js";
import { CategoryCard } from "../components/CategoryCard";
import { useListCategories } from "../domain/category/category.hook";

export default function Home() {
  const categories = useListCategories();

  return (
    <div class="grid gap-6 grid-cols-1 md:grid-cols-3 p-4">
      <Show when={categories.data}>
        <For each={categories.data}>
          {(category, index) => (
            <CategoryCard
              category={category}
              href={`/category/${category.id}`}
              highPriority={index() < 2}
            />
          )}
        </For>
      </Show>
    </div>
  );
}
