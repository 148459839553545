import { masterDarijaClient } from "@/infrastructure/query.client";
import type { CreateCategoryBodyType } from "@master-darija/contract";
import { createMutation, createQuery } from "@tanstack/solid-query";
import { toast } from "solid-sonner";

const ADMIN_CATEGORY_LIST_QUERY_KEY = "admin_category_list";
export function useAdminListCategories() {
  return createQuery(() => ({
    queryKey: [ADMIN_CATEGORY_LIST_QUERY_KEY],
    queryFn: async () => {
      const result =
        await masterDarijaClient.adminCategories.listCategoriesAsAdmin({
          query: {
            limit: 20,
          },
        });
      if (result.status !== 200) {
        toast.error("Failed to fetch categories");

        throw new Error("Failed to fetch data");
      }
      return result.body;
    },
  }));
}
useAdminListCategories.queryKey = [ADMIN_CATEGORY_LIST_QUERY_KEY];

export function useCreateCategory() {
  return createMutation(() => ({
    mutationFn: async (body: CreateCategoryBodyType) => {
      const result = await masterDarijaClient.adminCategories.create({
        body,
      });
      if (result.status !== 200) {
        toast.error("Failed to create category");

        throw new Error("Failed to fetch data");
      }
      return result.body;
    },
  }));
}
