import { createStore } from "solid-js/store";
import type { User } from "@auth0/auth0-spa-js";
import { setUser as setUserToSentry } from "@sentry/browser";

interface UserStoreType {
  user: User | null;
  authToken: string | null;
}

const [userStore, setUserStore] = createStore<UserStoreType>({
  user: null,
  authToken: null,
});

function setUser({ user, authToken }: { user: User; authToken: string }) {
  setUserStore({ user });
  setUserToSentry(user);
  setUserStore({ authToken });
}
export { userStore, setUser };
