import { For } from "solid-js";
import { CategoryCard } from "../../components/CategoryCard";
import type { CategoryType } from "../../domain/category/category.hook";

interface SubCategoriesPageParamsType {
  categories: CategoryType[];
}
export function SubCategoriesPage({ categories }: SubCategoriesPageParamsType) {
  return (
    <div class="grid gap-6 grid-cols-2 md:grid-cols-3 p-4">
      <For each={categories}>
        {(category) => (
          <CategoryCard
            category={category}
            href={`/category/${category.id}`}
            size="small"
          />
        )}
      </For>
    </div>
  );
}
