import type { Component } from "solid-js";
import { Show } from "solid-js";
import { pwaInfo } from "virtual:pwa-info";
import { useRegisterSW } from "virtual:pwa-register/solid";
import { Button } from "./ui/button";
import { Card, CardFooter, CardHeader, CardTitle } from "./ui/card";

console.log(pwaInfo);

const ReloadPrompt: Component = () => {
  // replaced dynamically
  const period = 60 * 60 * 1000;

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onRegisteredSW(swUrl, r) {
      if (period <= 0) return;
      if (r?.active?.state === "activated") {
        registerPeriodicSync(period, swUrl, r);
      } else if (r?.installing) {
        r.installing.addEventListener("statechange", (e) => {
          const sw = e.target as ServiceWorker;
          if (sw.state === "activated") registerPeriodicSync(period, swUrl, r);
        });
      }
    },
  });

  return (
    <Show when={needRefresh()}>
      <Card class="fixed mt-5 z-50">
        <CardHeader>
          <CardTitle>
            <span>
              New content available, click on reload button to update.
            </span>
          </CardTitle>
        </CardHeader>
        <CardFooter>
          <Button onClick={() => updateServiceWorker(true)}>Reload</Button>
        </CardFooter>
      </Card>
    </Show>
  );
};

export default ReloadPrompt;

/**
 * This function will register a periodic sync check every hour, you can modify the interval as needed.
 */
function registerPeriodicSync(
  period: number,
  swUrl: string,
  r: ServiceWorkerRegistration
) {
  if (period <= 0) return;

  setInterval(async () => {
    if ("onLine" in navigator && !navigator.onLine) return;

    const resp = await fetch(swUrl, {
      cache: "no-store",
      headers: {
        cache: "no-store",
        "cache-control": "no-cache",
      },
    });

    if (resp?.status === 200) await r.update();
  }, period);
}
