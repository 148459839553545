import { ImageRoot, Image, ImageFallback } from "@/components/ui/image";
import { PlayCircle } from "feather-icons-solid/play-circle";
import { Button } from "./ui/button";
import { Card, CardContent, CardHeader } from "./ui/card";

export type WordType = {
  translations: {
    arabic: string;
    darija: string;
    english: string;
  };
  soundUrl: string;
  imageUrl: string;
};

interface WordCardPropsType {
  word: WordType;
  playSound: (soundSrc: string) => void;
}
export function WordCard(props: WordCardPropsType) {
  return (
    <Card>
      <CardHeader class="space-y-3">
        <Button
          variant="ghost"
          onClick={() => {
            props.playSound(props.word.soundUrl);
          }}
        >
          <PlayCircle width="2em" height="2em" />
        </Button>
        <div class="flex justify-center">
          <ImageRoot class="h-48 w-48 rounded-full shadow-xl">
            <Image width={192} height={192} src={props.word.imageUrl} />
            <ImageFallback>⬜</ImageFallback>
          </ImageRoot>
        </div>
      </CardHeader>
      <CardContent>
        <div class="flex flex-col items-center text-2xl gap-y-2">
          <div>{props.word.translations.arabic}</div>
          <div class="text-3xl">{props.word.translations.darija}</div>
          <div>{props.word.translations.english}</div>
        </div>
      </CardContent>
    </Card>
  );
}
