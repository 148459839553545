import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  TextField,
  TextFieldLabel,
  TextFieldRoot,
} from "@/components/ui/textfield";
import type { CreateCategoryBodyType } from "@master-darija/contract";

interface CreateCategoryCardPropsType {
  isPending: boolean;
  onSubmit: (data: CreateCategoryBodyType) => void;
}
export function CreateCategoryCard({
  isPending,
  onSubmit,
}: CreateCategoryCardPropsType) {
  const handleSubmit = async (event: SubmitEvent) => {
    event.preventDefault();

    if (isPending) {
      return;
    }

    const form = new FormData(event.target as HTMLFormElement);

    const categoryToCreate: CreateCategoryBodyType = {
      name: form.get("name") as string,
      title: form.get("title") as string,
      description: form.get("description") as string,
    };
    onSubmit(categoryToCreate);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Please fill this fields</CardTitle>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <div class="flex flex-col gap-y-4">
            <TextFieldRoot class="w-full max-w-xs">
              <TextFieldLabel>Name</TextFieldLabel>
              <TextField name="name" title="Name" placeholder="Name" />
            </TextFieldRoot>

            <TextFieldRoot class="w-full max-w-xs">
              <TextFieldLabel>Title</TextFieldLabel>
              <TextField name="title" title="Title" placeholder="Title" />
            </TextFieldRoot>

            <TextFieldRoot class="w-full max-w-xs">
              <TextFieldLabel>Description</TextFieldLabel>
              <TextField
                name="description"
                title="Description"
                placeholder="Description"
              />
            </TextFieldRoot>
          </div>
        </CardContent>
        <CardFooter>
          <Button type="submit" disabled={isPending}>
            Create
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
}
