import "@fontsource/ubuntu";
import "solid-devtools";
import { render } from "solid-js/web";
import App from "./app";
import "./styles/main.css";

import { initOpenPanel } from "./monitoring/openpanel";
import { initSentry } from "./monitoring/sentry";

initSentry();
initOpenPanel();

render(() => <App />, document.getElementById("root") as HTMLElement);
