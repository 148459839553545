import { OpenPanel } from "@openpanel/web";
import { appConfig } from "../infrastructure/config/configuration";

export function initOpenPanel() {
  if (appConfig.openPanel.disabled) {
    console.log("OpenPanel is disabled... skipping initialization");
    return;
  }
  const op = new OpenPanel({
    clientId: appConfig.openPanel.key,
    trackScreenViews: true,
    trackOutgoingLinks: true,
    trackAttributes: true,
  });
  return op;
}
