import {
  useAdminListCategories,
  useCreateCategory,
} from "@/domain/admin/adminCategory.hook";
import type { CreateCategoryBodyType } from "@master-darija/contract";
import { CreateCategoryCard } from "./CreateCategory.form";
import { useQueryClient } from "@tanstack/solid-query";

export function CreateCategoryPage() {
  const queryClient = useQueryClient();
  const createCategory = useCreateCategory();

  const handleSubmit = async (data: CreateCategoryBodyType) => {
    createCategory.mutate(data);

    queryClient.invalidateQueries({
      queryKey: useAdminListCategories.queryKey,
    });
  };

  return (
    <div class="p-4 space-y-8">
      <div>
        <h1 class="text-4xl text-emerald-100 font-bold">Create a category</h1>

        <CreateCategoryCard
          onSubmit={handleSubmit}
          isPending={createCategory.isPending}
        />
      </div>
    </div>
  );
}
