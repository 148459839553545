import * as Sentry from "@sentry/browser";
import { appConfig } from "../infrastructure/config/configuration";

export function initSentry() {
  Sentry.init({
    enabled: !appConfig.sentry.disabled,
    dsn: appConfig.sentry.dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.5,
    // tracePropagationTargets: ["localhost"],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
