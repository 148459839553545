import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Image, ImageFallback, ImageRoot } from "@/components/ui/image";
import { LoadingSpinner } from "@/components/ui/spinner";
import {
  Tabs,
  TabsContent,
  TabsIndicator,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";
import { searchAssetsQuery } from "@/domain/asset/asset.hook";
import type { AssetFamilyType, AssetType } from "@master-darija/contract";
import { debounce } from "@solid-primitives/scheduled";
import { createQuery } from "@tanstack/solid-query";
import { Plus as PlusIcon } from "feather-icons-solid/plus";
import { For, Show, createSignal } from "solid-js";
import { TextField, TextFieldRoot } from "../../../components/ui/textfield";
import { AdminLinkButton } from "../components/adminLink";

const TABS = ["categoryIcon", "wordImage", "wordSound"] as const;

export function AdminAssetsPage() {
  return (
    <div class="p-4 space-y-4">
      <div class="flex justify-end space-x-4">
        <AdminLinkButton
          variant="default"
          href="/admin/assets/create"
          icon={<PlusIcon />}
        >
          Create an asset
        </AdminLinkButton>
      </div>
      <Card class="bg-slate-100">
        <Tabs defaultValue="categoryIcon">
          <CardHeader>
            <TabsList>
              <For each={TABS}>
                {(tab) => <TabsTrigger value={tab}>{tab}</TabsTrigger>}
              </For>
              <TabsIndicator />
            </TabsList>
          </CardHeader>
          <CardContent>
            <For each={TABS}>
              {(tab) => (
                <TabsContent value={tab}>
                  <AssetList assetFamily={tab} />
                </TabsContent>
              )}
            </For>
          </CardContent>
        </Tabs>
      </Card>
    </div>
  );
}

function AssetList({ assetFamily }: { assetFamily: AssetFamilyType }) {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = createSignal("");
  const setSearch = debounce(
    (input: string) => setDebouncedSearchTerm(input),
    300
  );

  const assets = createQuery(() =>
    searchAssetsQuery({
      assetFamily,
      search: debouncedSearchTerm,
    })
  );

  function handleSearch(event: Event) {
    setSearch((event.target as HTMLInputElement).value);
  }
  return (
    <div class="space-y-4">
      <TextFieldRoot class="w-full max-w-xs">
        <TextField
          type="search"
          name="Search"
          title="Search"
          placeholder="Search"
          on:input={handleSearch}
        />
      </TextFieldRoot>
      <h2 class="text-4xl text-gray-800 font-bold capitalize">{assetFamily}</h2>
      <div class="grid gap-6 grid-cols-1 md:grid-cols-3 p-4">
        <Show when={assets.isLoading}>
          <LoadingSpinner />
        </Show>
        <Show when={assets.data}>
          <For each={assets.data}>
            {(asset) => (
              <AssetPreview asset={asset} assetFamily={assetFamily} />
            )}
          </For>
        </Show>
        <Show when={assets.error}>
          {assets.error?.message ?? "An Error occured"}
        </Show>
      </div>
    </div>
  );
}

function AssetPreview({
  asset,
  assetFamily,
}: {
  asset: AssetType;
  assetFamily: AssetFamilyType;
}) {
  return (
    <Card>
      <CardHeader class="text-lg font-bold">{asset.name}</CardHeader>
      <CardContent>
        <div class="flex flex-col items-center">
          <Show when={assetFamily !== "wordSound"}>
            <ImageRoot
              fallbackDelay={600}
              class="h-24 w-24 rounded-full shadow-xl"
            >
              <Image src={asset.url} />
              <ImageFallback>Unavailable !</ImageFallback>
            </ImageRoot>
          </Show>

          <Show when={assetFamily === "wordSound"}>
            {/* biome-ignore lint/a11y/useMediaCaption: no caption for a single word */}
            <audio src={asset.url} controls class="w-full max-w-72" />
          </Show>
        </div>
      </CardContent>
    </Card>
  );
}
