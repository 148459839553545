import { Image, ImageFallback, ImageRoot } from "@/components/ui/image";
import { type JSX, Suspense, createMemo } from "solid-js";

import { HelpCircle } from "feather-icons-solid/help-circle";
import { Home } from "feather-icons-solid/home";
import { Menu as MenuIcon } from "feather-icons-solid/menu";
import { X as IconX } from "feather-icons-solid/x";

import { useListCategories } from "@/domain/category/category.hook";
import ReloadPrompt from "../ReloadPrompt";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "../ui/drawer";
import { LeftMenu, type LeftMenuItemType } from "./LeftMenu";
import { ColorModeProvider, ColorModeScript } from "@kobalte/core";
import { Toaster } from "../ui/sonner";

const MENU_ITEMS: LeftMenuItemType[] = [
  {
    label: "Home",
    href: "/",
    icon: <Home />,
  },
  {
    label: "About",
    href: "/about",
    icon: <HelpCircle />,
  },
];

export function Layout({ children }: { children?: JSX.Element }) {
  const categories = useListCategories();
  const categoryItems = createMemo(
    () =>
      categories.data?.map(({ id, name, imageUrl }) => ({
        label: name.charAt(0).toUpperCase() + name.slice(1),
        href: `/category/${id}`,
        icon: (
          <ImageRoot class="w-6 h-6">
            <Image class="w-6 h-6" src={imageUrl} />
            <ImageFallback>⬜</ImageFallback>
          </ImageRoot>
        ),
      })) ?? []
  );

  return (
    <Drawer side="left">
      {({ setOpen }) => (
        <>
          <header class="sticky top-0 z-50 w-full border-b border-border/20 bg-white/30 backdrop-blur-sm supports-[backdrop-filter]:bg-white/20">
            <div class="flex justify-start h-14 items-center">
              <DrawerTrigger class="p-4">
                <MenuIcon class="text-secondary h-8 w-8 transition-transform duration-200 hover:scale-105 active:scale-95" />
              </DrawerTrigger>
              <div class="flex flex-grow items-center justify-center gap-x-10">
                <h1 class="ml-4 text-xl text-gray-900 font-extrabold tracking-wide drop-shadow-sm animate-fade-in-slow">
                  Master Darija
                </h1>

                <div class="h-10">
                  <svg
                    class="h-10 rounded"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 900 600"
                  >
                    <title>Morocco flag</title>
                    <path fill="#c1272d" d="M0 0h900v600H0z" />
                    <path
                      d="M450 224.315l-44.467 136.87 116.401-84.559h-143.87l116.403 84.559z"
                      fill="none"
                      stroke="#006233"
                      stroke-width="14.63"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </header>

          <ReloadPrompt />
          <main class="flex-1 overflow-y-auto">{children}</main>
          <Suspense>
            <DrawerContent draggable={false} class="bg-secondary md:max-w-80">
              <div class="h-screen">
                <DrawerClose class="absolute top-2 right-2">
                  <IconX width="2em" height="2em" />
                </DrawerClose>
                <LeftMenu
                  items={MENU_ITEMS}
                  categories={categoryItems()}
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </div>
            </DrawerContent>
            <Toaster />
          </Suspense>
        </>
      )}
    </Drawer>
  );
}
