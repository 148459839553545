import { Route, Router } from "@solidjs/router";
import { QueryClient, QueryClientProvider } from "@tanstack/solid-query";
import { type Component, ErrorBoundary, lazy } from "solid-js";

import { Layout } from "./components/layout/Layout";
import Home from "./pages/Home";
import { IsAuthenticatedRouteGuard } from "./pages/admin/IsAuthenticated.guard.tsx";
import { CreateCategoryPage } from "./pages/admin/categories/CreateCategoryPage.tsx";
import Category from "./pages/category/Category.page.tsx";
import { AdminAssetsPage } from "./pages/admin/assets/AssetList.page.tsx";
import { AdminAssetCreationPage } from "./pages/admin/assets/AssetCreation.page.tsx";

const LoginPage = lazy(() => import("./pages/Login"));
const AdminCategoryPage = lazy(() => import("./pages/admin/Admin.page.tsx"));
const AboutPage = lazy(() => import("./pages/About"));
const NotFoundPage = lazy(() => import("./pages/404"));

const queryClient = new QueryClient();

const App: Component = () => {
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <QueryClientProvider client={queryClient}>
        <Router root={Layout}>
          <Route path="/" component={Home} />
          <Route path="/category/:categoryId" component={Category} />
          <Route path="/about" component={AboutPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/admin" component={IsAuthenticatedRouteGuard}>
            <Route path="/category" component={AdminCategoryPage} />
            <Route path="/category/create" component={CreateCategoryPage} />
            <Route path="assets" component={AdminAssetsPage} />
            <Route path="assets/create" component={AdminAssetCreationPage} />
          </Route>
          <Route path="**" component={NotFoundPage} />
        </Router>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
