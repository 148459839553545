import { appApiContract } from "@master-darija/contract";
import type { ServerInferResponses } from "@ts-rest/core";
import { initClient } from "@ts-rest/core";
import { userStore } from "../domain/category/user/user.store";

export const masterDarijaClient = initClient(appApiContract, {
  baseUrl: "/api",
  baseHeaders: {
    Authorization: () => {
      const authToken = userStore.authToken;
      return authToken ? `Bearer ${authToken}` : "";
    },
  },
});

export type AppApiType = typeof appApiContract;
export type ApiResponseShapesType = ServerInferResponses<AppApiType>;
