import type { AssetFamilyType } from "@master-darija/contract";
import { queryOptions } from "@tanstack/solid-query";
import type { Accessor } from "solid-js";
import { toast } from "solid-sonner";
import { masterDarijaClient } from "../../infrastructure/query.client";

interface SearchAssetsParamsType {
  assetFamily: AssetFamilyType | null;
  search?: Accessor<string>;
}

async function searchAssets({ assetFamily, search }: SearchAssetsParamsType) {
  if (!assetFamily) {
    return;
  }
  const result = await masterDarijaClient.assets.search({
    query: {
      limit: 100_000,
      family: assetFamily,
      search: search?.(),
    },
  });
  if (result.status !== 200) {
    toast.error("Failed to search assets");

    throw new Error("Failed to fetch data");
  }
  return result.body;
}

export const searchAssetsQuery = (params: SearchAssetsParamsType) =>
  queryOptions({
    queryKey: ["asset_search", params.assetFamily, params.search?.()],
    queryFn: () => searchAssets(params),
    enabled: !!params.assetFamily,
  });
