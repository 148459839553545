import {
  type AppApiType,
  masterDarijaClient,
} from "@/infrastructure/query.client";
import type { ClientInferResponseBody } from "@ts-rest/core";
import type { WordType } from "../../datum";
import { createQuery } from "@tanstack/solid-query";
import { toast } from "solid-sonner";

export function useListCategories() {
  return createQuery(() => ({
    queryKey: ["category_list"],
    queryFn: async () => {
      const result = await masterDarijaClient.categories.list({
        query: {
          limit: 20,
          onlyRoot: true,
        },
      });
      if (result.status !== 200) {
        toast.error("Failed to list categories");

        throw new Error("Failed to fetch data");
      }
      return result.body;
    },
  }));
}

export function useGetCategory(params: { categoryId: string }) {
  return createQuery(() => ({
    queryKey: ["category", params.categoryId],
    queryFn: async () => {
      const result = await masterDarijaClient.categories.get({
        params: {
          id: params.categoryId,
        },
      });
      if (result.status !== 200) {
        toast.error("Failed to get category");

        throw new Error("Failed to fetch data");
      }
      return result.body;
    },
    select: (response) => {
      const mappedWords: WordType[] | null =
        response.words?.map((word) => ({
          ...word,
          translations: {
            arabic:
              word.translations.find((t) => t.language === "Arabic")?.value ??
              "",
            darija:
              word.translations.find((t) => t.language === "Darija")?.value ??
              "",
            english:
              word.translations.find((t) => t.language === "English")?.value ??
              "",
          },
        })) ?? null;
      return {
        ...response,
        words: mappedWords,
      };
    },
  }));
}

export type CategoryType = ClientInferResponseBody<
  AppApiType["categories"]["list"],
  200
>[number];

export type CategoryDetailsType = ClientInferResponseBody<
  AppApiType["categories"]["get"],
  200
>;
