import { z } from "zod";

const configuration = {
  authentication: {
    domain: import.meta.env.VITE_AUTH_DOMAIN,
    cliendId: import.meta.env.VITE_AUTH_CLIENT_ID,
    audience: import.meta.env.VITE_AUTH_AUDIENCE,
    redirectUri: import.meta.env.VITE_AUTH_REDIRECT_URI,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    disabled: import.meta.env.VITE_SENTRY_DISABLED === "true",
  },
  openPanel: {
    key: import.meta.env.VITE_OP_KEY,
    disabled: import.meta.env.VITE_OP_DISABLED === "true",
  },
};

const ConfigurationZodSchema = z.object({
  authentication: z.object({
    domain: z.string(),
    cliendId: z.string(),
    audience: z.string(),
    redirectUri: z.string().url(),
  }),
  sentry: z.object({
    dsn: z.string().url(),
    disabled: z.boolean().optional(),
  }),
  openPanel: z.object({
    key: z.string(),
    disabled: z.boolean().optional(),
  }),
});

export const appConfig = ConfigurationZodSchema.parse(configuration);
