import { createSignal, onCleanup } from "solid-js";

export interface PlayerApiType {
  play: (soundSrc: string) => void;
  stop: () => void;
  isPlaying: () => boolean;
}

interface PlayerPropsType {
  onPlay?: () => void;
  onEnded?: () => void;
  ref?: (api: PlayerApiType) => void;
}

export function Player(props: PlayerPropsType) {
  let player: HTMLAudioElement;
  const [isPlaying, setIsPlaying] = createSignal(false);

  function stop() {
    player.pause();
    player.currentTime = 0;
    props.onEnded?.();
    setIsPlaying(false);
  }

  async function play(soundSrc: string) {
    player.src = soundSrc;
    props.onPlay?.();
    try {
      await player.play();
      setIsPlaying(true);
    } catch (error) {
      // Autoplay prevented. Probably because the user hasn't interacted with the page yet.
      console.log(error);
    }
  }

  onCleanup(() => {
    if (isPlaying()) {
      stop();
    }
  });

  if (props.ref) {
    props.ref({
      play,
      stop,
      isPlaying,
    });
  }

  return (
    <div>
      <audio
        ref={(el) => (player = el)}
        preload="auto"
        autoplay={false}
        onEnded={() => props.onEnded?.()}
      />
    </div>
  );
}
