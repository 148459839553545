import { useParams } from "@solidjs/router";
import { Match, Switch, createMemo } from "solid-js";
import { useGetCategory } from "../../domain/category/category.hook";
import { LoadingSpinner } from "../../components/ui/spinner";
import { SubCategoriesPage } from "./SubCategories.page";
import { WordsPage } from "./Words.page";

export default function Category() {
  const params = useParams<{ categoryId: string }>();

  const category = useGetCategory(params);

  const pageType = createMemo(() => {
    if (!category.data) {
      return "loading";
    }
    const body = category.data;
    if ((body.subCategories?.length ?? 0) > 0) {
      return "subCategories";
    }
    return "words";
  });

  return (
    <Switch fallback={<LoadingSpinner />}>
      <Match when={pageType() === "subCategories"}>
        <SubCategoriesPage categories={category.data?.subCategories ?? []} />
      </Match>
      <Match when={pageType() === "words"}>
        <div class="flex justify-center py-4">
          <h1 class="text-4xl text-emerald-100 font-bold capitalize">
            {category.data?.name}
          </h1>
        </div>
        <WordsPage words={category.data?.words ?? []} />
      </Match>
    </Switch>
  );
}
