import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Combobox,
  ComboboxContent,
  ComboboxInput,
  ComboboxItem,
  ComboboxTrigger,
} from "@/components/ui/combobox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TextFieldLabel, TextFieldRoot } from "@/components/ui/textfield";
import { useCreateAsset } from "@/domain/admin/adminAsset.hook";
import { searchAssetsQuery } from "@/domain/asset/asset.hook";
import {
  ASSET_FAMILIES,
  type AssetFamilyType,
  UploadAssetZodSchema,
} from "@master-darija/contract";
import { useNavigate } from "@solidjs/router";
import { createQuery } from "@tanstack/solid-query";
import { Show, createMemo, createSignal } from "solid-js";

export function AdminAssetCreationPage() {
  const navigate = useNavigate();

  const [selectedFamily, setSelectedFamily] =
    createSignal<AssetFamilyType | null>(null);

  const createAsset = useCreateAsset();

  const assets = createQuery(() =>
    searchAssetsQuery({
      assetFamily: selectedFamily(),
    })
  );

  const familyOpts = createMemo(() =>
    ASSET_FAMILIES.map((family) => ({
      value: family,
      label: family,
    }))
  );
  const selectedFamilyValue = createMemo(
    () => familyOpts().find((opt) => opt.value === selectedFamily()) ?? null
  );

  const referenceIdOptions = createMemo(
    () =>
      assets.data?.map((asset) => ({
        value: asset.id,
        label: asset.name,
      })) ?? []
  );

  async function handleSubmit(event: SubmitEvent) {
    event.preventDefault();

    if (createAsset.isPending) {
      return;
    }

    const form = new FormData(event.target as HTMLFormElement);

    const assetToCreate = UploadAssetZodSchema.parse({
      assetFamily: form.get("assetFamily"),
      referenceId: form.get("referenceId"),
      file: new File(
        [form.get("file") as Blob],
        (form.get("file") as File).name,
        { type: (form.get("file") as File).type }
      ),
    });

    await createAsset.mutate(assetToCreate);
    navigate("/admin/assets");
  }

  return (
    <div class="p-4">
      <Card>
        <CardHeader>
          <CardTitle>Please fill this fields</CardTitle>
        </CardHeader>
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Show when={createAsset.isError}>
              <div class="bg-red-100 text-red-800 p-2 rounded">
                {createAsset.error?.message}
              </div>
            </Show>

            <div class="flex flex-col gap-y-4">
              <SimpleSelect
                name="assetFamily"
                value={selectedFamilyValue()}
                options={familyOpts()}
                onChange={(newValue) => {
                  if (newValue) {
                    setSelectedFamily(newValue.value);
                  }
                }}
              />
              <SimpleAutoComplete
                name="referenceId"
                options={referenceIdOptions()}
              />

              <FileInput
                name="file"
                hint={
                  selectedFamily() === "wordSound" ? "Audio file" : "Image file"
                }
              />
            </div>
          </CardContent>
          <CardFooter>
            <Button type="submit" disabled={createAsset.isPending}>
              Create
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
}

interface SimpleSelectOptionType<T extends string> {
  label: string;
  value: T;
}
interface SimpleSelectPropsType<T extends string> {
  name: string;
  options: SimpleSelectOptionType<T>[];
  error?: string;
  value?: SimpleSelectOptionType<T> | null;
  onChange?: (value: SimpleSelectOptionType<T> | null) => void;
}
export function SimpleSelect<T extends string>(
  props: SimpleSelectPropsType<T>
) {
  return (
    <Select
      placeholder="Select an option"
      name={props.name}
      options={props.options}
      optionValue="value"
      optionTextValue="label"
      itemComponent={(props) => (
        <SelectItem item={props.item}>{props.item.rawValue.label}</SelectItem>
      )}
      value={props.value}
      onChange={props.onChange}
    >
      <Select.Label>{props.name}</Select.Label>
      <Select.HiddenSelect />

      <SelectTrigger>
        <SelectValue<SimpleSelectOptionType<T>>>
          {(state) => state.selectedOption().label}
        </SelectValue>
      </SelectTrigger>
      <SelectContent />
      <Show when={props.error}>
        <Select.ErrorMessage>{props.error}</Select.ErrorMessage>
      </Show>
    </Select>
  );
}

interface SimpleAutoCompletePropsType<T extends string> {
  name: string;
  options: SimpleSelectOptionType<T>[];
  value?: SimpleSelectOptionType<T> | null;
  onChange?: (value: SimpleSelectOptionType<T> | null) => void;
}
export function SimpleAutoComplete<T extends string>(
  props: SimpleAutoCompletePropsType<T>
) {
  return (
    <Combobox
      name={props.name}
      options={props.options}
      optionValue="value"
      optionTextValue="label"
      optionLabel="label"
      placeholder="Please select an asset"
      itemComponent={(props) => (
        <ComboboxItem item={props.item}>
          {props.item.rawValue.label}
        </ComboboxItem>
      )}
      value={props.value}
      onChange={props.onChange}
    >
      <Combobox.HiddenSelect />
      <ComboboxTrigger>
        <ComboboxInput />
      </ComboboxTrigger>
      <ComboboxContent />
    </Combobox>
  );
}

function FileInput(props: { name: string; hint?: string }) {
  return (
    <TextFieldRoot>
      <TextFieldLabel
        for={props.name}
        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        File
      </TextFieldLabel>
      <input
        type="file"
        id={props.name}
        name={props.name}
        class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400
            file:bg-gray-50 file:border-0
            file:me-4
            file:py-3 file:px-4
            dark:file:bg-neutral-700 dark:file:text-neutral-400"
      />
      <Show when={props.hint}>
        <p
          class="mt-1 text-sm text-gray-500 dark:text-gray-300"
          id="file_input_help"
        >
          {props.hint}
        </p>
      </Show>
    </TextFieldRoot>
  );
}
